import { createContext, useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import axios from "axios";

export const baseURL = process.env.REACT_APP_BASE_URL_API;
export const baseURLApp = process.env.REACT_APP_BASE_URL;
export const baseUrlAssets = "https://erp-boz.pages.dev";

export const versaoClienteDTO = {
  versaoPrincipal: 3,
  versaoRelease: 1,
  versaoCorrecao: "00",
};

export const ApiContext = createContext({});

const rotasCacheadasGet = {};

export const ApiProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const infoAPI = {
    baseURL: baseURL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  function definirToken(token) {
    if (token) {
      setToken(token);
    } else {
      perderSessao();
    }
  }

  function get(url, semCache = false) {
    let salvarDados = false;
    let nomeRotaCacheada = "";

    //Verificar se a url informada está na lista de cacheamento
    if (rotasCacheadasGet[url] != undefined) {
      //Existe a rota na lista de cacheamento, verificar se existe dados salvos
      const dados = localStorage.getItem(rotasCacheadasGet[url]);

      if (!semCache) {
        if (dados != undefined && dados != null) {
          return new Promise((resolve, reject) => {
            resolve({ data: JSON.parse(dados) });
          });
        } else {
          nomeRotaCacheada = rotasCacheadasGet[url];
          salvarDados = true;
        }
      } else {
        //Foi selecionado que não quer cache, setar para salvar
        nomeRotaCacheada = rotasCacheadasGet[url];
        salvarDados = true;
      }
    }

    const api = axios.create(infoAPI);
    api.interceptors.response.use((response) => {
      return response
    }, (error) => {
      return Promise.reject(error)
    })
    return new Promise((resolve, reject) => {
      api
        .get(url)
        .then((response) => {


          //Verifiar se deve salvar dados em cache
          if (salvarDados) {
            localStorage.setItem(
              nomeRotaCacheada,
              JSON.stringify(response.data)
            );
          }

          if (response?.status && response?.status === 401) {
            perderSessao();
          } else if (response?.status && response?.status === 503) {
            toast.warn("Não foi possível estabelecer conexão com API");
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  function postFile(url, data) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .post(url, data, { responseType: "arraybuffer" })
        .then((response) => {
          if (response?.status && response?.status === 401) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  function getFile(url,) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .get(url, { responseType: "arraybuffer" })
        .then((response) => {
          if (response?.status && response?.status === 401) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  function post(url, data) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      api
        .post(url, data)
        .then((response) => {
          if (
            response?.status &&
            response?.status === 401
          ) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (
            error.response?.status &&
            error.response?.status === 401
          ) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  function put(url, data) {
    const api = axios.create(infoAPI);

    return new Promise((resolve, reject) => {
      api
        .put(url, data)
        .then((response) => {
          if (response?.status && response?.status === 401) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  function patch(url, data) {
    const api = axios.create(infoAPI);

    return new Promise((resolve, reject) => {
      api
        .patch(url, data)
        .then((response) => {
          if (response?.status && response?.status === 401) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  function del(url, data, body) {
    const api = axios.create(infoAPI);

    return new Promise((resolve, reject) => {
      api
        .delete(url + objetoParaPathVariables(data), { data: body })
        .then((response) => {
          if (response?.status && response?.status === 401) {
            //Verificar se está apontando para a rota de desconectar, para evitar que o usuario fique preso caso a API demore para responder
            if (url == "/usuario-sessao/client/usuario/encerrar-sessao") {
              limparSessao();
            } else {
              perderSessao();
            }
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }


  function objetoParaPathVariables(objeto) {
    if (typeof objeto === "undefined") {
      return ''
    }

    const pathVariables = [];

    for (const chave in objeto) {
      if (objeto.hasOwnProperty(chave)) {
        pathVariables.push(`${chave}=${encodeURIComponent(objeto[chave])}`);
      }
    }

    return "?" + pathVariables.join("&");
  }

  function perderSessao() {
    setToken(null);
    toast.info("Sua sessão expirou. Por favor, faça login novamente");
    setTimeout(() => {
      realizarLogoff();
    }, 2000);
  }

  async function realizarLogoff() {
    try {
      await del("/usuario-sessao/client/usuario/encerrar-sessao")
        .then((res) => {
          limparSessao();
        })
        .catch((erro) => {
          limparSessao();
        });
    } catch (error) { }
  }

  function limparSessao() {
    localStorage.removeItem("lista_dashboards");
    localStorage.removeItem("_da");
    window.location.href = `${baseURLApp}/login`;
  }

  function getAsset(caminho) {
    return `${baseUrlAssets}/${caminho}`;
  }

  function file(url, file, base64 = false) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      if (base64) {
        formData.append("base64", file);
      } else {
        formData.append("file", file);
      }
      api
        .post(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.status && response?.status === 401) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }
  function filePut(url, file, base64 = false) {
    const api = axios.create(infoAPI);
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      if (base64) {
        formData.append("base64", file);
      } else {
        formData.append("file", file);
      }
      api
        .put(url, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response?.status && response?.status === 401) {
            perderSessao();
          } else {
            resolve(response);
          }
        })
        .catch((error) => {
          if (error.response?.status && error.response?.status === 401) {
            perderSessao();
          }
          reject(error);
        });
    });
  }

  return (
    <ApiContext.Provider
      value={{
        get,
        postFile,
        getFile,
        post,
        put,
        del,
        file,
        patch,
        definirToken,
        getAsset,
        token,
        realizarLogoff,
        filePut
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);
