import { ContainerTabs, TabItem } from "./styles";

export const TabBar = ({
  items,
  indexSelecionado,
  setIndexSelecionado,
  setLoading = () => { },
  style,
  tabMaior = false,
  marginTabs = true,
  disabled = false,
  itemStyle = {},
  textoMaiusculo = true,
  itemsDesabilitados = [],
}) => {
  console.log(items)
  return (
    <ContainerTabs
      value={indexSelecionado}
      onChange={(event, valorNovo) => {
        setIndexSelecionado(valorNovo);
        setLoading();
      }}
      scrollButtons
      allowScrollButtonsMobile
      variant="scrollable"
      aria-label="scrollable auto tabs example"
      style={style}
    >
      {items.map((titulo, index) => (
        <TabItem
          sx={{
            textTransform: textoMaiusculo ? "uppercase" : "none",
          }}
          label={titulo}
          key={titulo + index}
          disabled={disabled || itemsDesabilitados.includes(titulo)}
          style={{
            width: tabMaior ? 200 : "",
            marginRight: marginTabs ? "32px" : "inherit",
            ...itemStyle,
          }}
          tabIndex={0}
        />
      ))}
    </ContainerTabs>
  );
};
